.searchPage-container{
  width:100%;
  padding-top: 0px;
  display: flex;
  position: relative;
  align-items: stretch;
  box-sizing: border-box;
}

.sidePanel-wrapper{
  display: flex;
  position: fixed;
  width: 20%;
  height:calc(100vh - 115px);
  z-index: 999;
}


form {
  display: flex;

}
.form-control {
  display: inline;
  margin: 0px 10px;
}

.listContainer{
  margin:auto auto;
  background-color: white;
  position: relative;
  display:flex;
  height:calc(100vh - 115px)
}

.list-spinner-container{
  margin-top: 32px;
  text-align: center;
  height: 100%;
  margin-left: auto;
  margin-right: auto;

}
.search-header{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin: 0 0 0 10px;
  width:100%;
}
.scroll-list{
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100% !important;
}

.employer-list-wrapper{
  z-index: 999;
  display: inline-block;
  margin: auto 10px;
  width:20vw;
  height: 95%;
  position: relative;
  padding-right: 8px;
  
}
.sidePanel{
z-index: -99;
left:0;
top:0;
position: relative;
height:100%;
width:100%;
background-color: transparent;
display: flex;
transition: left .5s ease-in-out;
min-width: 300px;
}

.fold1{
  left:-100%;
}
.fold2{
  left:-200%;
}
.fold3{
 left:-300%
}

.unfold1{
  left:0%;
}
.unfold2{
  left:-100%;
}
.unfold3{
  left:-200%;
}

.filterUnfold{
left:0;
}
.part2 {
left:0%;
z-index: -10;
}
.part2-unfold {
  left:0%;
  z-index: -10;
  }
.part3 {
left:0%;
z-index: -20;
}
.part4 {
z-index: -30;
left:0%;
box-shadow: 10px 0px 10px -11px rgb(184, 183, 183);
}



.filter-window{
/* z-index: -99;
position: absolute; */
/* top:0;
left:0%; */
position: absolute;
height:100%;
width:100%;
background-color: white;
display: flex;
flex-direction: column;
transition: left .5s ease-in-out;
/* border-left-style: solid;
border-left-color: grey;
border-width: 1px; */
}

.filter-window-head-row{
height:32px !important;
}
.filter-window-container-row{
  height:100% !important;
  width: 100% !important;
}

.filter-window-row{
  padding-top: 8px;
  height: calc(100% - 16px) !important;
}

.filter-window-container-col{
  height: calc(100% - 16px) !important;
}

.filter-window-header{
margin-bottom: 8px;
width: 100%;
}

.filter-window-body{
  width: 100%;
  display: flex;
  flex-direction: row;
}

 .filter-divider{
  height:100% !important;
 }

 .filter-content{
   width: 90%;
   min-width: 0px;
   margin: 8px auto;
 }

 .filter-window-caption{
 max-height: 32px;
 margin: 0;
 padding: 0;
 }

.employerList{
  background-color: white;
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
  transition: left .5s ease-in-out;
}

.employerMap{
  display: inline-block;
  width: 100%;
  margin-left: 20%;
  
}



