.TableElement{
    width: 50%;
    display: inline-block;
    overflow: hidden;
    
}

.DataGrid{
    width:auto;
}


.DataRow{
    width:100%;
    align-self: center;
    margin-bottom: 24px;
}

.DataColumn{
    width:100%;
}

.Container{
    align-content: center;
    margin-top: 3rem;
}

.EditButton{
    padding-left: 10px;
    font-size: 15px;
}
.HeaderText{
    display: inline;
    font-size: xx-large;
}
.HeaderRow{
margin-bottom: 1rem;
}
.HeaderSubRow{
    align-items: baseline;
}