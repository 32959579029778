.project-filter-content{
    max-height:100%;
    width: 100%;
    height: 100%;
}

.project-scroll-list
{
    max-height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100% !important;
}

.project-search-header{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width:100%;
  }