@import (reference) './../../../../App.less';

.cityList-cityItem{
    padding:8px;
}

.cityList-cityItem:hover{
    background-color: @LoewYellow;
    box-shadow: @GlobalShadow;
}
@primary-color: #999999;@link-color: #ef7500;@highlight-color: #ffcc00;