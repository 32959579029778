.socialComponent-container
{
    background-color: #084069;
    display: flex;
    flex-direction: column;
    height:400px;
    width: 100%;
    margin:auto;
    margin-top: -90px;
    z-index: -1;
}
.socialComponent-centerItems
{
    margin:auto;
    display: flex;
    flex-direction: column;
}
.socialComponent-bannerText{
    color: white;
}

.socialComponent-icons{
    display: flex;
    margin: auto;
    color: white;
    font-size: 1.5em;
}

.socialComponent-socialIcon{
    margin: 0 8px;
}