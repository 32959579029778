@import '~antd/dist/antd.less';
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

.main-content {
}

.before{
  height: 115px;
}

@font-face {
  font-family: 'Lato';
  src: local('Lato') url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap') format('truetype');
}


@LoewYellow:rgb(255, 204, 0);
@MbOrange:#ef7500;
@GlobalShadow:2px 2px 10px -2px rgb(184, 183, 183);
@LoewDarkGrey:rgb(73,80,80);
@ListHover:rgb(236, 236, 236);