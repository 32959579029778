@import (reference) './../../../../App.less';

.projectList-projectItem{
    padding:8px;
}

.projectList-projectItem:hover{
    transition: 0.1s;
    background-color: @LoewYellow;
    box-shadow: @GlobalShadow;
}

.projectList-project:hover{
    transition: 0.2s;
    background-color: @ListHover;
}
@primary-color: #999999;@link-color: #ef7500;@highlight-color: #ffcc00;