.sidePanel-container{
  height:100%;
  width:100%;
  display: flex;
  position: absolute;
  box-shadow: 10px 0px 10px -11px rgb(184, 183, 183);
  background-color: white;
  will-change: left;
  /* transition: left .5s ease-in-out;  */
}
.sidePanel-filterRow{
  will-change: left;
  transition: left .5s ease-in-out;
}

.geometry {
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.2));
}
