.passwordreset-pagecontainer{
  height: 80vh;
  margin: auto;
  width:100%;
  display: flex;
}

.passwordreset-form{
  display: flex;
  flex-direction: column;
  max-width: 80%;
  min-width: 20%;
  margin: auto !important;
}

.passwordreset-form-button
{
  width:100%;
}


@primary-color: #999999;@link-color: #ef7500;@highlight-color: #ffcc00;