.joinBanner-container
{
    background-color: #ef7500;;
    display: flex;
    flex-direction: column;
    height:400px;
    width: 100%;
    margin:auto;
    z-index: -1;
}
.joinBanner-centerItems
{
    margin:auto;
    display: flex;
    flex-direction: column;
}
.joinBanner-bannerText,
.joinBanner-bannerHeader{
    color: white;
}
