@import (reference) './../App.less' ;

.ClickableIcon:hover{
    cursor: pointer;
    color: @LoewYellow !important;
    opacity: 1; 
}
.ClickableIcon{
  transition-duration: 0.3s;
}

.ColorGrey{
  color: @LoewDarkGrey !important;
}

.ColorWhite{
  color: #ffffff !important;
}

.ColorYellow{
  color: @LoewYellow !important;
}

.rotatedObject90{
  transform:rotate(90deg);
}

.EditButton{
    padding-left: 10px;
    font-size: 15px;
}

.pageContainer
{
    position: relative;
    z-index: 0;
    max-width: 100vw;
    margin:auto;
}

html *{
     font-family:  "Lato", sans-serif;
    //  font-weight: 400;
    //  font-size: 16px;
}

[class*="transparentBackground"]
{
    background-color:#ffffffaa !important;
}

[class*="transparentBackground"] *
{
    background-color:transparent !important;
}

[class*="transparentBackground"]:hover,
[class*="transparentBackground"]:active
{
    background-color:#ffffff !important;
}


.subtext{
    font-family: Kreon, serif !important;
    font-weight: 300 !important;
    font-size: 18px !important;
    line-height: 1.25em !important;
    letter-spacing: .005em !important;
    color: #2c2c2c;
  }
  
  .titletext{
    font-family: Kreon !important;
    color: rgb(8, 64, 105);
    font-size: 18px;
    font-weight: 600;
    font-stretch: 100%;
  }

  .titletext-h1{
    font-family: Kreon !important;
    color: rgb(8, 64, 105);
    font-size: 34pt;
    line-height: 42pt;
    font-weight: 330;
    font-stretch: 100%;
  }
 
  .titletext-h1-standard{
    font-family:  "Arial";
    color: @LoewDarkGrey;
    font-size: 48px;
    font-weight: 900;
    line-height: normal;
  }
  .titletext-h2,
  .titletext-h2 *{
    font-family: 'Lato',sans-serif !important;
    color: @LoewDarkGrey;
    font-size: 30px;
    line-height: 28pt;
    font-weight: 700;
    font-stretch: 100%;
  }
  .titletext-h2-standard{
    font-family: 'Lato', sans-serif;
    color: @LoewDarkGrey;
    font-size: 24pt;
    font-weight: 400;
    font-stretch: 100%;
    line-height: 28pt;
  }
  .smalltext{
    font-family: Kreon !important;
    font-weight: 320;
    font-size: 14pt !important;
    line-height: 18pt;
    letter-spacing: .015em;
    color: #2c2c2c;
    margin-bottom: 0;
  }
  .smalltext-standard,
  .smalltext-standard *{
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 14pt !important;
    line-height: 18pt;
    font-stretch: 100%;
    color: #2c2c2c;
    margin-bottom: 0;
    font-optical-sizing: auto;
  }

  .smalltext-standard-lightgray{
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 12pt !important;
    line-height: 18pt;
    font-stretch: 100%;
    color: #8a8a8a;
    margin-bottom: 0;
    font-optical-sizing: auto;
  }

  .smalltext-bold{
    font-family: Kreon !important;
    font-weight: 500;
    font-size: 14pt;
    line-height: 18pt;
    letter-spacing: .015em;
    color: #2c2c2c;
    margin-bottom: 0;
  }

  .smalltext-bold-standard{
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    font-size: 14pt;
    line-height: 18pt;
    letter-spacing: .005em;
    color: rgb(44, 44, 44);
    margin-bottom: 0;
  }
  
.listItem:hover{
    background-color: @MbOrange;
    color: white;
    box-shadow: 2px 2px 10px -2px rgb(184, 183, 183);
    cursor: pointer;
    }
    
.listItem:hover .subtext{
      background-color: @MbOrange;
      color: white;
      }
    
.listItem:hover .smalltext{
        background-color: @MbOrange;
        color: white;
        }
 
.listItemSelected{
    background-color: @MbOrange;
    color: white;
    box-shadow: 2px 2px 10px -2px rgb(184, 183, 183);
}
.listItemSelected .subtext{
    background-color: @MbOrange;
    color: white;
}
.listItemSelected .smalltext{
    background-color: @MbOrange;
    color: white;
}

.hyphenate {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

.customNav:hover{
  color:  @MbOrange;
  cursor: pointer;
}

.custom-scroll-window{
  padding-right: 8px;
  width:100%;
}

.yellow-button{
  background-color: @LoewYellow !important;
  border-color: @LoewYellow !important;
}

.yellow-icon{
color: @LoewYellow !important;
}

.shadowElement{
  box-shadow: 10px 0px 10px -11px rgb(184, 183, 183) !important;
}

.shadowElement-360{
  box-shadow: 0px 0px 10px 0px rgb(109, 108, 108) !important;
}

.roundedImagePreviewMask{
  border-radius: 500px;
}
@primary-color: #999999;@link-color: #ef7500;@highlight-color: #ffcc00;