.ClickableIcon:hover{
    opacity: 0.5;
    cursor: pointer;
}

.EditButton{
    padding-left: 10px;
    font-size: 15px;
}

.pageContainer
{
    max-width: 100%;
    margin:auto;
}

html *{
    font-family: "Source Sans Pro", sans-serif;
}

[class*="transparentBackground"]
{
    background-color:#ffffffaa !important;
}

[class*="transparentBackground"] *
{
    background-color:transparent !important;
}

[class*="transparentBackground"]:hover,
[class*="transparentBackground"]:active
{
    background-color:#ffffff !important;
}
